import request from '@/utils/request'

// 查询消息记录列表
export function listMessageRecord (query) {
  return request({
    url: '/message/messageRecord/list',
    method: 'get',
    params: query
  })
}

// 查询消息记录详细
export function getMessageRecord (traceId) {
  return request({
    url: '/message/messageRecord/' + traceId,
    method: 'get'
  })
}

// 新增消息记录
export function addMessageRecord (data) {
  return request({
    url: '/message/messageRecord',
    method: 'post',
    data: data
  })
}

// 修改消息记录
export function updateMessageRecord (data) {
  return request({
    url: '/message/messageRecord',
    method: 'put',
    data: data
  })
}

// 删除消息记录
export function delMessageRecord (traceId) {
  return request({
    url: '/message/messageRecord/' + traceId,
    method: 'delete'
  })
}

// 导出消息记录
export function exportMessageRecord (query) {
  return request({
    url: '/message/messageRecord/export',
    method: 'get',
    params: query
  })
}
