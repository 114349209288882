<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="消息链路id" prop="traceId" >
        <a-input v-model="form.traceId" placeholder="请输入消息链路id" />
      </a-form-model-item>
      <a-form-model-item label="模板 id" prop="templateId" >
        <a-input v-model="form.templateId" placeholder="请输入模板 id" />
      </a-form-model-item>
      <a-form-model-item label="消息应用id" prop="appId" >
        <a-input v-model="form.appId" placeholder="请输入消息应用id" />
      </a-form-model-item>
      <a-form-model-item label="消息状态" prop="messageStatus" >
        <a-radio-group v-model="form.messageStatus" button-style="solid">
          <a-radio-button value="">请选择字典生成</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="用户类型" prop="userType" >
        <a-select placeholder="请选择用户类型" v-model="form.userType">
          <a-select-option value="" >请选择字典生成</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="消息推送人" prop="pushUser" >
        <a-input v-model="form.pushUser" placeholder="请输入消息推送人" />
      </a-form-model-item>
      <a-form-model-item label="发送渠道类型" prop="channelType" >
        <a-select placeholder="请选择发送渠道类型" v-model="form.channelType">
          <a-select-option value="" >请选择字典生成</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="消息类型" prop="messageType" >
        <a-select placeholder="请选择消息类型" v-model="form.messageType">
          <a-select-option value="" >请选择字典生成</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="推送范围" prop="pushRange" >
        <a-input v-model="form.pushRange" placeholder="请输入推送范围" />
      </a-form-model-item>
      <a-form-model-item label="是否重试消息" prop="retried" >
        <a-input v-model="form.retried" placeholder="请输入是否重试消息" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getMessageRecord, addMessageRecord, updateMessageRecord } from '@/api/message/messageRecord'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        traceId: null,
        templateId: null,
        appId: null,
        messageStatus: 0,
        userType: null,
        pushUser: null,
        channelType: null,
        messageType: null,
        pushRange: null,
        retried: null,
        createTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        traceId: [
          { required: true, message: '消息链路id不能为空', trigger: 'blur' }
        ],
        templateId: [
          { required: true, message: '模板 id不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        traceId: null,
        templateId: null,
        appId: null,
        messageStatus: 0,
        userType: null,
        pushUser: null,
        channelType: null,
        messageType: null,
        pushRange: null,
        retried: null,
        createTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const traceId = row ? row.traceId : ids
      getMessageRecord(traceId).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.traceId !== undefined && this.form.traceId !== null) {
            updateMessageRecord(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addMessageRecord(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
