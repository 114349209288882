var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "消息链路id", prop: "traceId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入消息链路id" },
                model: {
                  value: _vm.form.traceId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "traceId", $$v)
                  },
                  expression: "form.traceId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "模板 id", prop: "templateId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入模板 id" },
                model: {
                  value: _vm.form.templateId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "templateId", $$v)
                  },
                  expression: "form.templateId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "消息应用id", prop: "appId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入消息应用id" },
                model: {
                  value: _vm.form.appId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "appId", $$v)
                  },
                  expression: "form.appId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "消息状态", prop: "messageStatus" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.messageStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "messageStatus", $$v)
                    },
                    expression: "form.messageStatus",
                  },
                },
                [
                  _c("a-radio-button", { attrs: { value: "" } }, [
                    _vm._v("请选择字典生成"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "用户类型", prop: "userType" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择用户类型" },
                  model: {
                    value: _vm.form.userType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "userType", $$v)
                    },
                    expression: "form.userType",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: "" } }, [
                    _vm._v("请选择字典生成"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "消息推送人", prop: "pushUser" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入消息推送人" },
                model: {
                  value: _vm.form.pushUser,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "pushUser", $$v)
                  },
                  expression: "form.pushUser",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "发送渠道类型", prop: "channelType" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择发送渠道类型" },
                  model: {
                    value: _vm.form.channelType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "channelType", $$v)
                    },
                    expression: "form.channelType",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: "" } }, [
                    _vm._v("请选择字典生成"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "消息类型", prop: "messageType" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择消息类型" },
                  model: {
                    value: _vm.form.messageType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "messageType", $$v)
                    },
                    expression: "form.messageType",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: "" } }, [
                    _vm._v("请选择字典生成"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "推送范围", prop: "pushRange" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入推送范围" },
                model: {
                  value: _vm.form.pushRange,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "pushRange", $$v)
                  },
                  expression: "form.pushRange",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否重试消息", prop: "retried" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入是否重试消息" },
                model: {
                  value: _vm.form.retried,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "retried", $$v)
                  },
                  expression: "form.retried",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }